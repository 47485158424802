<template>
  <div class="page">
    <van-nav-bar
      fixed
      title="备品备件"
      left-arrow
      @click-left="navBack"
    ></van-nav-bar>
    <div class="search">
      <div class="search-condition">
        <div class="search-total">共{{ total }}条结果</div>
      </div>
    </div>
    <van-pull-refresh
      ref="vanList"
      v-model="refreshing"
      class="data-list"
      @refresh="pullGetAgain"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText || '没有更多了'"
        error-text="加载失败，请稍后再试！"
        @load="onLoad"
      >
        <van-row class="table-header">
          <van-col span="5">备件编号</van-col>
          <van-col span="5">备件名称</van-col>
          <van-col span="5">备件型号</van-col>
          <van-col span="5">需求数量</van-col>
          <van-col span="4">单位</van-col>
        </van-row>
        <van-row v-for="item in list" :key="item.id" class="table-tr">
          <van-col class="table-td" span="5"> {{ item.code }}</van-col>
          <van-col class="table-td" span="5"> {{ item.name }}</van-col>
          <van-col class="table-td" span="5"> {{ item.modelNo }}</van-col>
          <van-col class="table-td" span="5"> {{ item.needNum }}</van-col>
          <van-col class="table-td" span="4"> {{ item.unitName }}</van-col>
        </van-row>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getEquipmentParts } from "./api";

export default {
  name: "EquipmentLinkAccessory",
  data() {
    return {
      total: 0,
      list: [],
      query: {
        page: 1,
        size: 10,
        equipmentRecordId: this.$route.params.id,
        equipmentId: this.$route.params.id,
        dataId: this.$route.params.id,
        equipId: this.$route.params.id,
        dataType: 1
      },
      refreshing: false,
      loading: false,
      finishedText: "",
      finished: false
    };
  },
  methods: {
    getList() {
      getEquipmentParts({ ...this.query }).then(res => {
        this.loading = false;
        if (res.list && res.list.length) {
          this.total = res.total;
          this.list = this.list.concat(res.list);
          this.query.page++;
        } else {
          if (!this.list.length) {
            this.finishedText = "没有更多了";
          } else {
            this.finishedText = "";
          }
          this.finished = true;
        }
      });
    },
    //下拉刷新
    pullGetAgain() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      // 将 page 重新设置为 1
      this.query.page = 1;
      this.onLoad();
    },
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      this.getList();
    },
    scrollTo() {
      this.scrollHeight = this.$refs.vanList.$el.scrollTop;
    },
    navBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  overflow: hidden;

  .search {
    margin-top: 58px;
    padding-top: 4px;

    .search-condition {
      display: flex;
      justify-content: center;

      .search-total {
        height: 18px;
        line-height: 18px;
        color: #8c8f97;
        text-align: center;
        font-size: 12px;
        padding: 12px 0 0;
        font-weight: 400;
      }
    }
  }

  .data-list {
    .table-header {
      height: 16px;
      line-height: 16px;
      text-align: left;
      padding: 12px 0 12px 4px;
      border-bottom: 1px solid #eceef2;
      font-size: 14px;
      font-weight: 600;
    }

    .table-tr {
      width: 100%;
      padding: 12px 0 12px 4px;
      font-size: 14px;

      .table-td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
